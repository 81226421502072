import '../styles/reset.css';
import '../styles/globals.css';
import '../styles/fonts.css';
import '../styles/text.css';
import '../wdyr';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import {ThemeProvider} from '@src/core/theme/ThemeProvider';
import {Wrapper} from '@src/common/Wrapper';
import {appWithTranslation} from 'next-i18next';
import {AuthProvider} from '../features/auth/logic/AuthProvider';
import {NextComponentType} from 'next';
import {UserRole} from '@src/models/user';
import {QueryClientProvider, QueryClient} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {useEffect} from 'react';
import {languageService} from '@src/features/my-profile/logic/language-service';
import toast, {ToastBar, Toaster} from 'react-hot-toast';
import {useRouter} from 'next/router';
import {isAxiosError} from 'axios';
import styled from 'styled-components';
import Image from 'next/image';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (failureCount >= 3) {
          return false;
        }
        if (!isAxiosError(error)) {
          return false;
        }
        if (isAxiosError(error)) {
          const statusCode = error.response?.status;
          if (!statusCode) {
            return true;
          }
          if (statusCode <= 500) {
            return false;
          }
          return true;
        }
        return true;
      },
    },
  },
});

type CustomAppProps = AppProps & {
  Component: NextComponentType & {auth?: {role: UserRole}};
};
const App = ({Component, pageProps}: CustomAppProps) => {
  const router = useRouter();

  useEffect(() => {
    languageService.changeLanguageIfIsSet(router);
    // effect is desired
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerStyle={{zIndex: 99999999}}
        toastOptions={{
          duration: 8000,
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({icon, message}) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <CloseIcon
                    onClick={() => toast.dismiss(t.id)}
                    width={17}
                    height={17}
                    src={'/icons/close-filled.svg'}
                    alt="close"
                  />
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>

      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Wrapper>
            <Head>
              <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              />
            </Head>
            {Component.auth ? (
              <AuthProvider role={Component.auth.role}>
                <Component {...pageProps} />
              </AuthProvider>
            ) : (
              <Component {...pageProps} />
            )}
            {process.env.NODE_ENV !== 'production' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </Wrapper>
        </ThemeProvider>
      </QueryClientProvider>
      <div id="modal-root"></div>
    </>
  );
};

const CloseIcon = styled(Image)`
  cursor: pointer;
`;

export default appWithTranslation(App);
